import React, { useState, useEffect, useMemo } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Badge,
  Progress,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import axios from 'supports/api';
import Loader from 'react-loader-spinner';
import AttendeeListModal from './AttendeeListModal';
import ScheduleForm from './ScheduleForm';

const DeleteModal = ({ isOpen, onClose, handleDelete, isLoading }) => {
  return (
    <Modal isOpen={isOpen} onClosed={onClose}>
      <ModalBody>
        <h5>Are you sure want to delete this program?</h5>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <Button color="danger" disabled style={{ width: 80 }}>
            <Loader type="Oval" color="white" height={18} width="100%" />
          </Button>
        ) : (
          <Button color="danger" onClick={handleDelete} style={{ width: 80 }}>
            Delete
          </Button>
        )}
        <Button color="info" onClick={onClose} style={{ width: 80 }}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ScheduleCard = ({
  id,
  startDate,
  endDate,
  code,
  startTime,
  endTime,
  practiceStartTime,
  practiceEndTime,
  study_method,
  study_schedule,
  seatsTaken,
  maxSeats,
  isLive,
  branch,
  refresh,
  programCategoryId,
  programCategoryType,
  isMultipleCurrency,
  createdBy,
  updatedBy,
  specialPrice,
  specialStartDate,
  specialEndDate,
  earlyPrice,
  earlyStartDate,
  earlyEndDate,
  termPrice,
  termStartDate,
  termEndDate,
  term2Price,
  term2StartDate,
  term2EndDate,
  normalPrice,
  priceTitle,
  priceTitleDesc,
  priceQuota,
  priceQuotaRemaining,
  saleEndDate,
}) => {
  const [attendeeModal, toggleAttendeeModal] = useState(false);
  const [editModal, toggleEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const activePrice = useMemo(() => {
    const promoList = [
      {
        label: 'Special Price',
        amount: specialPrice,
        startDate: specialStartDate,
        endDate: specialEndDate,
      },
      {
        label: 'Early Bird',
        amount: earlyPrice,
        startDate: earlyStartDate,
        endDate: earlyEndDate,
      },
      {
        label: 'Term 1',
        amount: termPrice,
        startDate: termStartDate,
        endDate: termEndDate,
      },
      {
        label: 'Term 2',
        amount: term2Price,
        startDate: term2StartDate,
        endDate: term2EndDate,
      },
    ];

    const price = promoList.find((promo) => {
      return (
        new Date() >= new Date(promo.startDate) &&
        new Date() <= new Date(promo.endDate)
      );
    }) || {
      label: 'Harga Normal',
      amount: normalPrice,
      endDate: saleEndDate,
    };

    const period =
      price.startDate && price.endDate
        ? `${moment(price?.startDate).format('DD MMM YYYY')} - ${moment(
            price?.endDate,
          ).format('DD MMM YYYY')}`
        : '-';

    const normalPricePeriod = `Ditutup pada ${moment(price?.endDate).format(
      'DD MMM YYYY',
    )}`;

    const amount = price.amount
      ? `Rp${price.amount.toLocaleString('id-ID')}`
      : '-';

    return {
      label: price.label || '-',
      amount,
      period: price.label === 'Harga Normal' ? normalPricePeriod : period,
      title: price.label === 'Harga Normal' ? '-' : priceTitle || '-',
      description: price.label === 'Harga Normal' ? '-' : priceTitleDesc || '-',
      quota: price.label === 'Harga Normal' ? '-' : priceQuota || '-',
      remainingQuota:
        price.label === 'Harga Normal' ? '-' : priceQuotaRemaining || '-',
    };
  }, [
    earlyEndDate,
    earlyPrice,
    earlyStartDate,
    normalPrice,
    priceQuota,
    priceQuotaRemaining,
    priceTitle,
    priceTitleDesc,
    saleEndDate,
    specialEndDate,
    specialPrice,
    specialStartDate,
    term2EndDate,
    term2Price,
    term2StartDate,
    termEndDate,
    termPrice,
    termStartDate,
  ]);

  useEffect(() => {
    axios
      .get(`/admin/program/attendees/${id}`)
      .then((res) => {
        setAttendees(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const onEditToggle = () => {
    toggleEditModal(!editModal);
  };

  const onPublishClick = () => {
    axios
      .put(`/admin/program/publish/${id}`)
      .then((res) => {
        refresh();
        alert('Succesfully published!');
      })
      .catch((err) => {
        alert(err);
        console.log(err.message);
      });
  };

  const onUnpublishClick = () => {
    axios
      .put(`/admin/program/unpublish/${id}`)
      .then((res) => {
        refresh();
        alert('Succesfully stopped');
      })
      .catch((err) => {
        alert(err);
        console.log(err.message);
      });
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await axios.delete(`/v2/program/${id}`);
      setIsLoading(false);
      setDeleteModal(false);
      refresh();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const RenderStatus = () => {
    if (isLive && moment(startDate) > moment()) {
      return <Badge style={{ backgroundColor: '#53b59f' }}>Live</Badge>;
    }
    if (!isLive) {
      return <Badge color="secondary">Not Live</Badge>;
    }
    return <Badge color="warning">Past</Badge>;
  };

  const RenderButtons = () => {
    const arrJSX = [];

    const isDeleteDisabled = attendees?.length !== 0;

    arrJSX.push(
      <>
        <Button
          disabled={isDeleteDisabled}
          key="deleteButton"
          className="rounded ml-1 my-1"
          color="danger"
          onClick={() => setDeleteModal(true)}
          id={`delete-button-${id}`}
        >
          Delete
        </Button>
        {isDeleteDisabled && (
          <UncontrolledTooltip
            placement="bottom"
            target={`delete-button-${id}`}
          >
            You cannot delete this program because there are already registered
            participants. Please transfer the class first.
          </UncontrolledTooltip>
        )}
      </>,
    );

    arrJSX.push(
      <Button
        key="editButton"
        className="rounded ml-1 my-1"
        color="info"
        onClick={onEditToggle}
      >
        Edit
      </Button>,
    );
    // arrJSX.push(
    //   <Link to={`/event-preview/${id}`} target="_blank" key="previewbutton">
    //     <Button key="previewButton" className="rounded ml-1 my-1" color="info">
    //       Preview
    //     </Button>
    //   </Link>,
    // );
    arrJSX.push(
      <Button
        key="attendeesButton"
        className="rounded ml-1 my-1"
        color="info"
        onClick={() => toggleAttendeeModal(!attendeeModal)}
      >
        Attendees
      </Button>,
    );
    // arrJSX.push(<Link to={`all-events/copy?id=${id}`} key="copybutton"><Button key="copyButton" className="rounded-0 ml-1 my-1" color="info">Copy</Button></Link>)

    if (!isLive) {
      arrJSX.push(
        <Button
          key="publishButton"
          className="rounded ml-1 my-1"
          color="success"
          onClick={onPublishClick}
        >
          Publish
        </Button>,
      );
    } else if (isLive && moment(endDate) > moment()) {
      arrJSX.push(
        <Button
          key="unpublishButton"
          className="rounded ml-1 my-1"
          color="danger"
          onClick={onUnpublishClick}
        >
          Unpublish
        </Button>,
      );
    }

    return (
      <span className="d-flex flex-row justify-content-end flex-wrap">
        {arrJSX}
      </span>
    );
  };

  const RenderDate = () => {
    if (moment(startDate).isSame(endDate)) {
      return `${moment(startDate).format('DD MMM YYYY')}`;
    }
    return `${moment(startDate).format('DD MMM')} - ${moment(endDate).format(
      'DD MMM YYYY',
    )}`;
  };

  return (
    <div className="p-2">
      <Card>
        <CardHeader>
          <div className="d-flex flex-row justify-content-between">
            <div>
              <RenderDate /> || {code}
            </div>
            <div>
              <RenderStatus />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column w-100">
              <div
                style={{ fontSize: '0.8rem' }}
                className="align-self-stretch"
              >
                <div className="font-weight-bold text-gray">
                  Seats Booked
                  <span className="font-weight-normal ml-1">{`(${seatsTaken}/${maxSeats})`}</span>
                </div>
                <div className="pb-1">
                  <Progress
                    color="info"
                    value={(seatsTaken / maxSeats) * 100}
                    center="true"
                  >
                    {Math.floor((seatsTaken / maxSeats) * 100)}%
                  </Progress>
                </div>
              </div>
              <div style={{ fontSize: '0.8rem' }}>
                <div className="font-weight-bold text-gray">
                  Attendees
                  <span className="font-weight-normal ml-1">{`(${attendees.length}/${maxSeats})`}</span>
                </div>
                <div className="pb-1">
                  <Progress
                    color="info"
                    striped
                    value={(attendees.length / maxSeats) * 100}
                    center="true"
                  >
                    {Math.floor((attendees.length / maxSeats) * 100)}%
                  </Progress>
                </div>
              </div>
            </div>
            <CardHeader
              style={{
                marginTop: '20px',
                paddingBlock: '8px',
                paddingInline: '20px',
                marginInline: '-20px',
                marginBottom: '20px',
              }}
            >
              <div className="font-weight-bold text-gray ">Program Info:</div>
            </CardHeader>
            <div className="row">
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Waktu Training</div>
                <div className="pb-1">{`${startTime} - ${endTime}`}</div>
              </div>

              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Waktu Practice</div>
                <div className="pb-1">
                  {practiceStartTime && practiceEndTime
                    ? `${practiceStartTime} - ${practiceEndTime}`
                    : 'N/A'}
                </div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Lokasi</div>
                <div className="pb-1">{branch.name}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Metode</div>
                <div className="pb-1">{study_method.name}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Jadwal</div>
                <div className="pb-1">{study_schedule.name}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Created by</div>
                <div className="pb-1">{createdBy || '-'}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Updated by</div>
                <div className="pb-1">{updatedBy || '-'}</div>
              </div>
            </div>
            <CardHeader
              style={{
                marginTop: '12px',
                paddingBlock: '8px',
                paddingInline: '20px',
                marginInline: '-20px',
                marginBottom: '20px',
              }}
            >
              <div className="font-weight-bold text-gray ">Active Price:</div>
            </CardHeader>
            <div className="row">
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Name</div>
                <div className="pb-1">{activePrice.label}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Amount</div>
                <div className="pb-1">{activePrice.amount}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Period</div>
                <div className="pb-1">{activePrice.period}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Title</div>
                <div className="pb-1">{activePrice.title}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Description</div>
                <div className="pb-1">{activePrice.description}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">Quota</div>
                <div className="pb-1">{activePrice.quota}</div>
              </div>
              <div className="col-3 pb-2">
                <div className="font-weight-bold text-gray">
                  Remaining Quota
                </div>
                <div className="pb-1">{activePrice.remainingQuota}</div>
              </div>
            </div>
            <div className="mt-2">
              <RenderButtons />
            </div>
          </div>
        </CardBody>
      </Card>
      <Modal isOpen={editModal} toggle={onEditToggle} size="lg">
        <ScheduleForm
          edit
          programId={id}
          programCategoryId={programCategoryId}
          programCategoryType={programCategoryType}
          toggle={onEditToggle}
          refresh={refresh}
          isMultipleCurrency={isMultipleCurrency}
        />
      </Modal>
      <AttendeeListModal
        isOpen={attendeeModal}
        toggle={() => toggleAttendeeModal(!attendeeModal)}
        programId={id}
        attendees={attendees}
      />
      <DeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        handleDelete={handleDelete}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ScheduleCard;
