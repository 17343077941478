import React, { useMemo } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { transactionInstallmentSchema } from '../schema/TransactionInstallmentSchema';

const TransactionInstallmentFormik = ({ children, installmentFormRef }) => {
  const initialBillingDate = useMemo(() => {
    return moment().add(1, 'month').toDate();
  }, []);

  return (
    <Formik
      innerRef={installmentFormRef}
      validationSchema={transactionInstallmentSchema}
      initialValues={{
        // Additional
        readOnly: false,

        // Transaction Installment
        type: '',
        tenure: 0,
        interest: 0,
        discount: 0,
        tuitionFee: 0,
        downPayment: 0,
        billingDate: initialBillingDate || null,
        isInstallment: false,
        dpTransactionId: null,
        accountReceivable: 0,
        dpOtherTransaction: 0,

        // Transaction Installment Payments
        installmentPayments: [],
      }}
    >
      {children}
    </Formik>
  );
};

export default TransactionInstallmentFormik;
