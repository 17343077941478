import Axios from 'supports/api';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import qs from 'query-string';
import { useHistory, useLocation, Redirect } from 'react-router';
import { checkForm } from 'supports/helpers/permissions';
import EFakturDataTab from './EFakturDataTab';
import EFakturListTab from './EFakturListTab';
import EFakturGenerateTab from './EFakturGenerateTab';
import EFakturUpload from './EFakturUpload';

const date = new Date();

const EFakturPage = () => {
  const [data, setData] = useState([]);
  const [dataTaxInvoice, setDataTaxInvoice] = useState([]);
  const [fromDate, setFromDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [toDate, setToDate] = useState(date);

  // Tax Invoice Date
  const [fromTaxInvoiceDate, setFromTaxInvoiceDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [toTaxInvoiceDate, setToTaxInvoiceDate] = useState(date);

  const [status, setStatus] = useState('idle');

  const location = useLocation();
  const history = useHistory();
  const access = useSelector((state) => state?.auth?.access);
  const allowedToRender = checkForm(access, 'EFaktur');
  const { tab } = qs.parse(location.search);

  const handleTabs = (tabQuery) => {
    const queryParam = qs.parse(location.search);
    const newQueryParam = {
      ...queryParam,
      tab: tabQuery,
    };
    history.push(`?${qs.stringify(newQueryParam)}`);
  };

  const queryParam = qs.parse(location.search);
  useEffect(() => {
    if (!queryParam.tab) {
      const newQueryParam = {
        ...queryParam,
        tab: 'list',
        page: 1,
      };
      history.push(`?${qs.stringify(newQueryParam)}`);
    }
  }, [history, queryParam]);

  const handleFetchTransactions = async () => {
    setStatus('loading');
    try {
      if (queryParam.tab === 'upload') {
        // filter by date in upload tab
        const options = {
          params: {
            fromTaxInvoiceDate,
            toTaxInvoiceDate,
          },
        };
        const res = await Axios.get(`/transaction/paid`, options);
        setDataTaxInvoice(res.data.result);
      } else {
        const options = {
          params: {
            fromDate,
            toDate,
          },
        };
        const res = await Axios.get(`/transaction/paid`, options);
        setData(res.data.result);
      }
      setStatus('success');
    } catch (err) {
      setStatus('error');
      console.log(err);
    }
  };

  if (!allowedToRender) {
    return <Redirect to="/" />;
  }

  return (
    <div
      className="white-box py-2 container-fluid"
      style={{ minHeight: '100vh' }}
    >
      <div className="row py-4">
        <div className="col-12 offset-md-1 col-md-10 py-3">
          <div>
            <div className="line-green mb-2" />
            <div className="general-title text-gray">Generate EFaktur</div>
          </div>
        </div>
        <div className="col-12 offset-md-1 col-md-10 py-3">
          {/* Start Tab Nav Region */}
          <Nav tabs>
            <NavItem>
              <NavLink
                active={tab === 'list'}
                onClick={() => {
                  handleTabs('list');
                }}
              >
                List
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={tab === 'data'}
                onClick={() => {
                  handleTabs('data');
                }}
              >
                Data
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={tab === 'generate'}
                onClick={() => {
                  handleTabs('generate');
                }}
              >
                Generate
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={tab === 'upload'}
                onClick={() => {
                  handleTabs('upload');
                }}
              >
                Upload E-Faktur
              </NavLink>
            </NavItem>
          </Nav>
          {/* End Tab NAv Region */}
          <TabContent activeTab={tab}>
            <TabPane tabId="list">
              {/* Start List Tab Region */}
              <EFakturListTab
                data={data}
                status={status}
                handleFetchTransactions={handleFetchTransactions}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
              />
              {/* End List Tab Region */}
            </TabPane>
            <TabPane tabId="data">
              {/* Start Data Tab Region */}
              <EFakturDataTab
                handleFetchTransactions={handleFetchTransactions}
              />
              {/* End Data Tab Region */}
            </TabPane>
            <TabPane tabId="generate">
              {/* Start Data Tab Region */}
              <EFakturGenerateTab />
              {/* End Data Tab Region */}
            </TabPane>
            <TabPane tabId="upload">
              <EFakturUpload
                data={dataTaxInvoice}
                status={status}
                handleFetchTransactions={handleFetchTransactions}
                fromDate={fromTaxInvoiceDate}
                setFromDate={setFromTaxInvoiceDate}
                toDate={toTaxInvoiceDate}
                setToDate={setToTaxInvoiceDate}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default EFakturPage;
