/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { Component } from 'react';
import moment from 'moment';
import TimePicker from 'react-time-picker';
import Loader from 'react-loader-spinner';
import axios from 'supports/api';
import {
  FormGroup,
  Input,
  FormFeedback,
  Button,
  InputGroup,
  Label,
  InputGroupAddon,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Table,
  Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { checkForm } from 'supports/helpers/permissions';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import HTMLEditor2 from '../HTMLEditor2';
import { InputForm, HTMLForm } from '../CustomForms';
import SpeakerCard from './SpeakerCard';
import SpeakerForm from './SpeakerForm';
import PriceInputModal from './PriceInputModal';
import PromoInputModal from './PromoInputModal';
// import CreateSpeakerModal from '../CreateSpeakerModal.js'

const nullParser = (rawValue) => {
  let value;
  if (typeof rawValue === 'string') {
    // NOTE: workaround for react-quill cannot properly clear content
    value = rawValue.replaceAll('<p><br></p>', '');
  } else {
    value = rawValue;
  }
  return value || null;
};

const SelectedSpeakers = ({
  speakers,
  onDelete,
  onActionSpeaker,
  listSpeakers,
}) => {
  const arrJSX = speakers.map((item, index) => {
    const selectedItem = listSpeakers.find(
      (selectedSpeaker) => selectedSpeaker.id === item.id,
    );

    return (
      <SpeakerCard
        key={JSON.stringify(selectedItem)}
        index={index}
        onDelete={onDelete}
        selectedData={selectedItem}
        onActionSpeaker={onActionSpeaker}
      />
    );
  });

  if (speakers.length > 0) {
    return (
      <div>
        <Label for="formselected" className="text-gray font-weight-bold">
          Selected Speakers
        </Label>
        {arrJSX}
      </div>
    );
  }
  return null;
};

const StartEndDatePicker = ({ handleStartEndDate, startDate, endDate }) => {
  const onStartSelect = (startDateValue) => {
    let tempEnd = endDate;
    if (startDateValue > tempEnd) {
      tempEnd = startDateValue;
    }
    handleStartEndDate(startDateValue, tempEnd);
  };

  const onEndSelect = (endDateValue) => {
    let tempStart = startDate;
    if (endDateValue < tempStart) {
      tempStart = endDateValue;
    }
    handleStartEndDate(tempStart, endDateValue);
  };

  return (
    <FormGroup className="d-flex justify-content-around">
      <div className="w-100">
        <Label for="formStartDate" className="text-gray font-weight-bold">
          Start Date
        </Label>
        <DatePicker
          className="form-control"
          selected={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MMM/yyy"
          onChange={onStartSelect}
          showMonthYearDropdown
          minDate={moment().subtract(24, 'months').toDate()}
          maxDate={moment().add(24, 'months').toDate()}
        />
      </div>
      <div className="w-100">
        <Label for="formEndDate" className="text-gray font-weight-bold">
          End Date
        </Label>
        <DatePicker
          className="form-control"
          selected={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MMM/yyy"
          onChange={onEndSelect}
          showMonthYearDropdown
          minDate={moment().subtract(24, 'months').toDate()}
          maxDate={moment().add(24, 'months').toDate()}
        />
      </div>
    </FormGroup>
  );
};

const EarlyStartEndDatePicker = ({
  // special
  handleSpecialStartDate,
  handleSpecialEndDate,
  datePickerSpecialStartDate,
  datePickerSpecialEndDate,
  specialStartDate,
  specialEndDate,

  // early
  handleEarlyStartDate,
  handleEarlyEndDate,
  datePickerEarlyStartDate,
  datePickerEarlyEndDate,
  earlyStartDate,
  earlyEndDate,

  // term
  handleTermStartDate,
  handleTermEndDate,
  handleTerm2StartDate,
  handleTerm2EndDate,
  datePickerTermStartDate,
  datePickerTermEndDate,
  datePickerTerm2StartDate,
  datePickerTerm2EndDate,
  termStartDate,
  termEndDate,
  term2StartDate,
  term2EndDate,

  // corporate sale
  handleCorporateSaleStartDate,
  handleCorporateSaleEndDate,
  corporateSaleStartDate,
  corporateSaleEndDate,
  datePickerCorporateSaleStartDate,
  datePickerCorporateSaleEndDate,

  // sale
  handleSaleEndDate,
  datePickerSaleEndDate,
  saleEndDate,

  // date picker
  openDatePicker,
  closeDatePicker,
}) => {
  return (
    <>
      <FormGroup className="d-flex justify-content-around">
        <div className="w-100">
          <Label
            for="formSpecialStartDate"
            className="text-gray font-weight-bold"
          >
            Special Price Start Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    specialStartDate.value
                      ? moment(specialStartDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={specialStartDate.invalid}
                  onClick={() => openDatePicker('datePickerSpecialStartDate')}
                />
                <FormFeedback invalid="true">
                  *{specialStartDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerSpecialStartDate}
            className="form-control"
            selected={specialStartDate.value}
            selectsStart
            startDate={specialStartDate.value}
            endDate={specialEndDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            // minDate={moment().subtract(12, 'months').toDate()}
            // maxDate={moment().add(12, 'months').toDate()}
            onChange={handleSpecialStartDate}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerSpecialStartDate')}
          />
        </div>
        <div className="w-100">
          <Label
            for="formSpecialEndDate"
            className="text-gray font-weight-bold"
          >
            Special Price End Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    specialEndDate.value
                      ? moment(specialEndDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={specialEndDate.invalid}
                  onClick={() => openDatePicker('datePickerSpecialEndDate')}
                />
                <FormFeedback invalid="true">
                  *{specialEndDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerSpecialEndDate}
            className="form-control"
            selected={specialEndDate.value}
            selectsEnd
            startDate={specialStartDate.value}
            endDate={specialEndDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            // minDate={moment().subtract(12, 'months').toDate()}
            // maxDate={moment().add(12, 'months').toDate()}
            onChange={handleSpecialEndDate}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerSpecialEndDate')}
          />
        </div>
      </FormGroup>

      <FormGroup className="d-flex justify-content-around">
        <div className="w-100">
          <Label
            for="formEarlyStartDate"
            className="text-gray font-weight-bold"
          >
            Early Bird Start Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    earlyStartDate.value
                      ? moment(earlyStartDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={earlyStartDate.invalid}
                  onClick={() => openDatePicker('datePickerEarlyStartDate')}
                />
                <FormFeedback invalid="true">
                  *{earlyStartDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerEarlyStartDate}
            className="form-control"
            selected={earlyStartDate.value}
            selectsStart
            startDate={earlyStartDate.value}
            endDate={earlyEndDate.value}
            dateFormat="dd/MMM/yyy"
            onChange={handleEarlyStartDate}
            showYearDropdown
            // minDate={moment().subtract(12, 'months').toDate()}
            // maxDate={moment().add(12, 'months').toDate()}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerEarlyStartDate')}
          />
        </div>
        <div className="w-100">
          <Label for="formEarlyEndDate" className="text-gray font-weight-bold">
            Early Bird End Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    earlyEndDate.value
                      ? moment(earlyEndDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={earlyEndDate.invalid}
                  onClick={() => openDatePicker('datePickerEarlyEndDate')}
                />
                <FormFeedback invalid="true">
                  *{earlyEndDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerEarlyEndDate}
            className="form-control"
            selected={earlyEndDate.value}
            selectsEnd
            startDate={earlyStartDate.value}
            endDate={earlyEndDate.value}
            dateFormat="dd/MMM/yyy"
            onChange={handleEarlyEndDate}
            showYearDropdown
            // minDate={moment().subtract(12, 'months').toDate()}
            // maxDate={moment().add(12, 'months').toDate()}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerEarlyEndDate')}
          />
        </div>
      </FormGroup>
      <FormGroup className="d-flex justify-content-around">
        <div className="w-100">
          <Label for="formTermStartDate" className="text-gray font-weight-bold">
            Term Start Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    termStartDate.value
                      ? moment(termStartDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={termStartDate.invalid}
                  onClick={() => openDatePicker('datePickerTermStartDate')}
                />
                <FormFeedback invalid="true">
                  *{termStartDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerTermStartDate}
            className="form-control"
            selected={termStartDate.value}
            selectsStart
            startDate={termStartDate.value}
            endDate={termEndDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            // minDate={moment().subtract(12, 'months').toDate()}
            // maxDate={moment().add(12, 'months').toDate()}
            onChange={handleTermStartDate}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerTermStartDate')}
          />
        </div>
        <div className="w-100">
          <Label for="formTermEndDate" className="text-gray font-weight-bold">
            Term End Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    termEndDate.value
                      ? moment(termEndDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={termEndDate.invalid}
                  onClick={() => openDatePicker('datePickerTermEndDate')}
                />
                <FormFeedback invalid="true">*{termEndDate.error}</FormFeedback>
              </>
            }
            open={datePickerTermEndDate}
            className="form-control"
            selected={termEndDate.value}
            selectsEnd
            startDate={termStartDate.value}
            endDate={termEndDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            // minDate={moment().subtract(12, 'months').toDate()}
            // maxDate={moment().add(12, 'months').toDate()}
            onChange={handleTermEndDate}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerTermEndDate')}
          />
        </div>
      </FormGroup>

      <FormGroup className="d-flex justify-content-around">
        <div className="w-100">
          <Label
            for="formTerm2StartDate"
            className="text-gray font-weight-bold"
          >
            Term 2 Start Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    term2StartDate.value
                      ? moment(term2StartDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={term2StartDate.invalid}
                  onClick={() => openDatePicker('datePickerTerm2StartDate')}
                />
                <FormFeedback invalid="true">
                  *{term2StartDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerTerm2StartDate}
            className="form-control"
            selected={term2StartDate.value}
            selectsStart
            startDate={term2StartDate.value}
            endDate={term2EndDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            // minDate={moment().subtract(12, 'months').toDate()}
            // maxDate={moment().add(12, 'months').toDate()}
            onChange={handleTerm2StartDate}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerTerm2StartDate')}
          />
        </div>
        <div className="w-100">
          <Label for="formTerm2EndDate" className="text-gray font-weight-bold">
            Term 2 End Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    term2EndDate.value
                      ? moment(term2EndDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={term2EndDate.invalid}
                  onClick={() => openDatePicker('datePickerTerm2EndDate')}
                />
                <FormFeedback invalid="true">
                  *{term2EndDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerTerm2EndDate}
            className="form-control"
            selected={term2EndDate.value}
            selectsEnd
            startDate={term2StartDate.value}
            endDate={term2EndDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            // minDate={moment().subtract(12, 'months').toDate()}
            // maxDate={moment().add(12, 'months').toDate()}
            onChange={handleTerm2EndDate}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerTerm2EndDate')}
          />
        </div>
      </FormGroup>

      <FormGroup className="d-flex justify-content-around">
        <div className="w-100">
          <Label for="formSaleEndDate" className="text-gray font-weight-bold">
            Sale End Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    saleEndDate.value
                      ? moment(saleEndDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={saleEndDate.invalid}
                  onClick={() => openDatePicker('datePickerSaleEndDate')}
                />
                <FormFeedback invalid="true">*{saleEndDate.error}</FormFeedback>
              </>
            }
            open={datePickerSaleEndDate}
            className="form-control"
            selected={saleEndDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            onChange={handleSaleEndDate}
            disabledKeyboardNavigation
            onClickOutside={() => closeDatePicker('datePickerSaleEndDate')}
          />
        </div>
      </FormGroup>

      <FormGroup className="d-flex justify-content-around">
        <div className="w-100">
          <Label for="formTermStartDate" className="text-gray font-weight-bold">
            Corporate Sale Start Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    corporateSaleStartDate.value
                      ? moment(corporateSaleStartDate.value).format(
                          'DD/MMM/YYYY',
                        )
                      : ''
                  }
                  invalid={corporateSaleStartDate.invalid}
                  onClick={() =>
                    openDatePicker('datePickerCorporateSaleStartDate')
                  }
                />
                <FormFeedback invalid="true">
                  *{corporateSaleStartDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerCorporateSaleStartDate}
            className="form-control"
            selected={corporateSaleStartDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            onChange={handleCorporateSaleStartDate}
            disabledKeyboardNavigation
            onClickOutside={() =>
              closeDatePicker('datePickerCorporateSaleStartDate')
            }
          />
        </div>

        <div className="w-100">
          <Label for="formTermEndDate" className="text-gray font-weight-bold">
            Corporate Sale End Date
          </Label>
          <DatePicker
            customInput={
              <>
                <Input
                  value={
                    corporateSaleEndDate.value
                      ? moment(corporateSaleEndDate.value).format('DD/MMM/YYYY')
                      : ''
                  }
                  invalid={corporateSaleEndDate.invalid}
                  onClick={() =>
                    openDatePicker('datePickerCorporateSaleEndDate')
                  }
                />
                <FormFeedback invalid="true">
                  *{corporateSaleEndDate.error}
                </FormFeedback>
              </>
            }
            open={datePickerCorporateSaleEndDate}
            className="form-control"
            selected={corporateSaleEndDate.value}
            dateFormat="dd/MMM/yyy"
            showYearDropdown
            onChange={handleCorporateSaleEndDate}
            disabledKeyboardNavigation
            onClickOutside={() =>
              closeDatePicker('datePickerCorporateSaleEndDate')
            }
          />
        </div>
      </FormGroup>
    </>
  );
};

const INITIAL_STATE = {
  branches: [],
  speakers: [],
  studyMethods: [],
  studySchedules: [],
  selectedSpeakerId: 'none',
  programHeaderObj: {},
  speakerForm: false,
  priceInputModalOpen: false,
  promoInputModalOpen: false,
  selectedPrice: {},
  selectedPromo: {},
};

const INITIAL_FORM = {
  branchId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Branch',
  },
  study_methodId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Study Method',
  },
  study_scheduleId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Study Schedule',
  },
  content: {
    value: '',
    valid: false,
    invalid: false,
    error: 'Please input content',
  },
  notes: {
    value: '',
    valid: true,
    invalid: false,
    error: 'Please input notes',
  },
  price: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    isFree: false,
    error: 'Input price',
  },
  specialPrice: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    isFree: false,
    error: 'Input special price',
  },
  earlyPrice: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    isFree: false,
    error: 'Input early bird price',
  },
  termPrice: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    isFree: false,
    error: 'Input term price',
  },
  term2Price: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    isFree: false,
    error: 'Input term 2 price',
  },
  corporatePrice: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    isFree: false,
    error: 'Input corporate price',
  },
  registrationFee: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    isFree: false,
    error: 'Input registration fee',
  },
  speakerIds: {
    value: [],
    valid: true,
    invalid: false,
    error: 'Please pick at least 1 speaker',
  },
  maxSeats: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please input max seats',
  },
  specialQuota: {
    value: 0,
    valid: true,
    invalid: false,
    error: 'Please input special price quota',
  },
  specialDesc: {
    value: '',
    valid: true,
    invalid: false,
    error: 'Please input special price description',
    help: 'ex.) Powered by Purwadhika',
  },
  specialInfo: {
    value: '',
    valid: true,
    invalid: false,
    error: 'Please input special price information',
    help: 'ex.) *S&K berlaku',
  },
  priceTitle: {
    value: '',
    valid: true,
    invalid: false,
    error: 'Please input price title',
    help: 'Optional. ex.) Launching Promo',
  },
  priceTitleDesc: {
    value: '',
    valid: true,
    invalid: false,
    error: 'Please input price title description',
    help: 'Optional. ex.) Powered by Purwadhika',
  },
  priceQuota: {
    value: 0,
    valid: true,
    invalid: false,
    error: 'Please input price quota',
    help: 'Optional. Will be displayed as "Promo ini hanya untuk X Pendaftar"',
  },
  priceQuotaRemaining: {
    value: 0,
    valid: true,
    invalid: false,
    error: 'Please input price remaining quota',
    help: 'Optional. Will be displayed as “Kuota Promo Hanya Tersisa X Lagi”',
  },
  priceInfo: {
    value: '',
    valid: true,
    invalid: false,
    error: 'Please input price information',
    help: 'Optional. ex.) *S&K berlaku',
  },
  startDate: {
    value: moment().startOf('date').toDate(),
    valid: true,
    invalid: false,
    error: 'Please select Start Date',
  },
  endDate: {
    value: moment().startOf('date').toDate(),
    valid: true,
    invalid: false,
    error: 'Please select End Date',
  },
  specialStartDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Special Price Start Date',
  },
  specialEndDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Special Price End Date',
  },
  earlyStartDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Early Bird Start Date',
  },
  earlyEndDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Early Bird End Date',
  },
  termStartDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Term Start Date',
  },
  termEndDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Term End Date',
  },
  term2StartDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Term 2 Start Date',
  },
  term2EndDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Term 2 End Date',
  },
  corporateSaleStartDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Corporate Sale Start Date',
  },
  corporateSaleEndDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Corporate Sale End Date',
  },
  saleEndDate: {
    value: null,
    valid: false,
    invalid: false,
    error: 'Please select Sale End Date',
  },
  startTime: {
    value: '09:00',
    valid: true,
    invalid: false,
    error: 'Please select Start Time',
  },
  endTime: {
    value: '16:00',
    valid: true,
    invalid: false,
    error: 'Please select End Time',
  },
  practiceStartTime: {
    value: '09:00',
    valid: true,
    invalid: false,
    error: 'Please select Practice Session Start Time',
  },
  practiceEndTime: {
    value: '16:00',
    valid: true,
    invalid: false,
    error: 'Please select Practice Session End Time',
  },
  days: {
    value: '',
    valid: false,
    invalid: false,
    help: "ex.) 'Tuesday', 'Mon-Fri, 'Tue, Thu'",
    error: 'Please input Training Session Days',
  },
  practiceDays: {
    value: '',
    valid: true,
    invalid: false,
    help:
      'Optional. Only input if Practice Session Days is different from Training Session Days.',
    additionalHelp: "ex.) 'Tuesday', 'Mon-Fri, 'Tue, Thu'.",
  },
  code: {
    value: '',
    valid: false,
    invalid: false,
    help: "ex.) 'JC-XX001",
    error: 'Please input code',
  },
  topicIds: {
    value: [],
    valid: false,
    invalid: false,
    error: 'Please select atleast 1 topic',
  },
  audience: {
    value: [{ item: '' }],
    valid: true,
    invalid: false,
    error: 'Input audience',
  },
  syllabus: {
    value: [{ item: '' }],
    valid: true,
    invalid: false,
    error: 'Input syllabus',
  },
  programPrices: {
    value: [],
    invalid: false,
  },
  programAdditionalPromos: {
    value: [],
    invalid: false,
  },
};

class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_FORM,
      datePickerSpeciaStartDate: false,
      datePickerSpecialEndDate: false,
      datePickerEarlyStartDate: false,
      datePickerEarlyEndDate: false,
      datePickerTermStartDate: false,
      datePickerTermEndDate: false,
      datePickerTerm2StartDate: false,
      datePickerTerm2EndDate: false,

      datePickerCorporateSaleStartDate: false,
      datePickerCorporateSaleEndDate: false,

      datePickerSaleEndDate: false,
    };
    this.categoryCheck = props.programCategoryType?.toLowerCase() === 'event';
    this.handleOpenDatePicker = this.handleOpenDatePicker.bind(this);
    this.handleCloseDatePicker = this.handleCloseDatePicker.bind(this);
  }

  componentDidMount() {
    this.getAllData();
  }

  static getDerivedStateFromProps = (newProps) => {
    this.categoryCheck =
      newProps.programCategoryType?.toLowerCase() === 'event';
    return null;
  };

  getAllData = () => {
    axios
      .get(`/branch/all`)
      .then((res) => {
        this.setState({ branches: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get('/program/methods')
      .then((res) => {
        this.setState({ studyMethods: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get('/program/schedules')
      .then((res) => {
        this.setState({ studySchedules: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    this.getSpeakers();
    if (this.props.edit) {
      this.getDataFromProgramID(this.props.programId);
    }
  };

  getDataFromProgramID = (id) => {
    axios
      .get(`/admin/program/schedule/${id}`)
      .then(async (res) => {
        const { result } = res.data;
        const {
          branchId,
          study_methodId,
          study_scheduleId,
          code,
          days,
          startDate,
          endDate,
          specialStartDate,
          specialEndDate,
          earlyStartDate,
          earlyEndDate,
          termStartDate,
          termEndDate,
          term2StartDate,
          term2EndDate,

          corporateSaleStartDate,
          corporateSaleEndDate,
          saleEndDate,
          startTime,
          endTime,
          practiceStartTime,
          practiceEndTime,
          normalPrice,
          specialPrice,
          earlyPrice,
          termPrice,
          term2Price,
          corporatePrice,
          registrationFee,
          maxSeats,
          speakers,
          workshopDetail,
          program_prices,
          programAdditionalPromos,
          specialDesc,
          specialQuota,
          specialInfo,
          practiceDays,
          priceTitle,
          priceTitleDesc,
          priceQuota,
          priceQuotaRemaining,
          priceInfo,
        } = result;

        await this.setState((prevState) => ({
          ...prevState,
          branchId: { ...prevState.branchId, value: branchId, valid: true },
          study_methodId: {
            ...prevState.study_methodId,
            value: study_methodId,
            valid: true,
          },
          study_scheduleId: {
            ...prevState.study_scheduleId,
            value: study_scheduleId,
            valid: true,
          },
          days: { ...prevState.days, value: days, valid: true },
          startDate: {
            ...prevState.startDate,
            value: new Date(startDate),
            valid: true,
          },
          endDate: {
            ...prevState.endDate,
            value: new Date(endDate),
            valid: true,
          },
          specialStartDate: {
            ...prevState.specialStartDate,
            value: specialStartDate ? new Date(specialStartDate) : '',
            valid: Boolean(specialStartDate),
          },
          specialEndDate: {
            ...prevState.specialEndDate,
            value: specialEndDate ? new Date(specialEndDate) : '',
            valid: Boolean(specialEndDate),
          },
          earlyStartDate: {
            ...prevState.earlyStartDate,
            value: earlyStartDate ? new Date(earlyStartDate) : '',
            valid: Boolean(earlyStartDate),
          },
          earlyEndDate: {
            ...prevState.earlyEndDate,
            value: earlyEndDate ? new Date(earlyEndDate) : '',
            valid: Boolean(earlyEndDate),
          },
          termStartDate: {
            ...prevState.termStartDate,
            value: termStartDate ? new Date(termStartDate) : '',
            valid: Boolean(termStartDate),
          },
          termEndDate: {
            ...prevState.termEndDate,
            value: termEndDate ? new Date(termEndDate) : '',
            valid: Boolean(termEndDate),
          },
          term2StartDate: {
            ...prevState.term2StartDate,
            value: term2StartDate ? new Date(term2StartDate) : '',
            valid: Boolean(term2StartDate),
          },
          term2EndDate: {
            ...prevState.term2EndDate,
            value: term2EndDate ? new Date(term2EndDate) : '',
            valid: Boolean(term2EndDate),
          },

          corporateSaleStartDate: {
            ...prevState.corporateSaleStartDate,
            value: corporateSaleStartDate
              ? new Date(corporateSaleStartDate)
              : null,
            valid: Boolean(corporateSaleStartDate),
          },
          corporateSaleEndDate: {
            ...prevState.corporateSaleEndDate,
            value: corporateSaleEndDate ? new Date(corporateSaleEndDate) : null,
            valid: Boolean(corporateSaleEndDate),
          },
          saleEndDate: {
            ...prevState.saleEndDate,
            value: saleEndDate ? new Date(saleEndDate) : '',
            valid: Boolean(saleEndDate),
          },
          startTime: { ...prevState.startTime, value: startTime, valid: true },
          endTime: { ...prevState.endTime, value: endTime, valid: true },
          practiceStartTime: {
            ...prevState.practiceStartTime,
            value: practiceStartTime,
            valid: true,
          },
          practiceEndTime: {
            ...prevState.practiceEndTime,
            value: practiceEndTime,
            valid: true,
          },
          price: {
            ...prevState.price,
            value: normalPrice,
            displayValue: normalPrice.toLocaleString(),
            valid: true,
          },
          specialPrice: {
            ...prevState.specialPrice,
            value: specialPrice,
            displayValue: (specialPrice || 0).toLocaleString(),
            valid: true,
          },
          earlyPrice: {
            ...prevState.earlyPrice,
            value: earlyPrice,
            displayValue: earlyPrice.toLocaleString(),
            valid: true,
          },
          termPrice: {
            ...prevState.termPrice,
            value: termPrice,
            displayValue: (termPrice || 0).toLocaleString(),
            valid: true,
          },
          term2Price: {
            ...prevState.term2Price,
            value: term2Price,
            displayValue: (term2Price || 0).toLocaleString(),
            valid: true,
          },
          corporatePrice: {
            ...prevState.corporatePrice,
            value: corporatePrice,
            displayValue: (corporatePrice || 0).toLocaleString(),
            valid: true,
          },
          maxSeats: { ...prevState.maxSeats, value: maxSeats, valid: true },
          registrationFee: {
            ...prevState.registrationFee,
            value: registrationFee,
            displayValue: registrationFee.toLocaleString(),
            valid: true,
          },
          speakerIds: { ...prevState.speakerIds, value: speakers, valid: true },
          code: {
            ...prevState.code,
            value: code || prevState.code.value,
            valid: !!code,
          },
          programPrices: {
            ...prevState.programPrices,
            value: program_prices || [],
          },
          programAdditionalPromos: {
            ...prevState.programAdditionalPromos,
            value: programAdditionalPromos || [],
          },
          specialDesc: {
            ...prevState.specialDesc,
            value: specialDesc,
            valid: true,
          },
          specialQuota: {
            ...prevState.specialQuota,
            value: specialQuota ?? 0,
            valid: true,
          },
          specialInfo: {
            ...prevState.specialInfo,
            value: specialInfo,
            valid: true,
          },
          practiceDays: {
            ...prevState.practiceDays,
            value: practiceDays,
            valid: true,
          },
          priceTitle: {
            ...prevState.priceTitle,
            value: priceTitle,
            valid: true,
          },
          priceTitleDesc: {
            ...prevState.priceTitleDesc,
            value: priceTitleDesc,
            valid: true,
          },
          priceQuota: {
            ...prevState.priceQuota,
            value: priceQuota ?? 0,
            valid: true,
          },
          priceQuotaRemaining: {
            ...prevState.priceQuotaRemaining,
            value: priceQuotaRemaining ?? 0,
            valid: true,
          },
          priceInfo: {
            ...prevState.priceInfo,
            value: priceInfo,
            valid: true,
          },
        }));

        if (workshopDetail) {
          const { content, syllabus, audience, notes } = workshopDetail;
          await this.setState((prevState) => ({
            content: {
              ...prevState.content,
              value: content || prevState.content.value,
              valid: !!content,
            },
            syllabus: {
              ...prevState.syllabus,
              value: JSON.parse(syllabus) || prevState.syllabus.value,
              valid: !!syllabus,
            },
            audience: {
              ...prevState.audience,
              value: JSON.parse(audience) || prevState.audience.value,
              valid: !!audience,
            },

            notes: {
              ...prevState.notes,
              value: notes || prevState.notes.value,
              valid: true,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSpeakers = () => {
    axios
      .get(`/admin/speaker/list`)
      .then((res) => {
        this.setState({ speakers: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggleModal = () => {
    this.setState({
      modal: (prevState) => !prevState.modal,
    });
  };

  togglePriceInputModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        priceInputModalOpen: !prevState.priceInputModalOpen,
      };
    });
  };

  togglePromoInputModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        promoInputModalOpen: !prevState.promoInputModalOpen,
      };
    });
  };

  handleStartEndDate = (startDate, endDate) => {
    this.setState((prevState) => ({
      startDate: { ...prevState.startDate, value: startDate },
      endDate: { ...prevState.endDate, value: endDate },
    }));
  };

  handleSelectedSaleEndDate = (name, selector, date, datePickerSelector) => {
    const rawStartDate = this?.state?.startDate?.value;
    const selectedDate = new Date(date?.toDateString());
    const startDate = new Date(rawStartDate?.toDateString());

    if (selectedDate > startDate) {
      alert(`${name} harus sebelum Start Date ZZZZ`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [selector]: { ...prevState[selector], value: date, valid: true },
      }));
      this.handleCloseDatePicker(datePickerSelector);
    }
  };

  handleSpecialStartDate = (dateInput) => {
    const date = new Date(moment(dateInput).startOf('day'));
    const terms = [
      'Tanggal Mulai Promo Special',
      'Tanggal Berakhir Promo Special',
    ];

    const { startDate, specialEndDate } = this.state;

    if (date > startDate.value) {
      alert(`${terms[0]} harus sebelum Start Date`);
    } else if (specialEndDate.value && date > specialEndDate.value) {
      alert(`${terms[0]} harus sebelum ${terms[1]}`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        specialStartDate: {
          ...prevState.specialStartDate,
          value: date,
          valid: true,
        },
      }));
      this.handleCloseDatePicker('datePickerSpecialStartDate');
    }
  };

  handleSpecialEndDate = (dateInput) => {
    const date = new Date(moment(dateInput).endOf('day'));
    const terms = [
      'Tanggal Mulai Promo Special',
      'Tanggal Berakhir Promo Special',
    ];

    const { startDate, specialStartDate } = this.state;

    if (date > startDate.value) {
      alert(`${terms[1]} harus sebelum Start Date`);
    } else if (specialStartDate.value && date < specialStartDate.value) {
      alert(`${terms[1]} harus sesudah ${terms[0]}`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        specialEndDate: {
          ...prevState.specialEndDate,
          value: date,
          valid: true,
        },
      }));
      this.handleCloseDatePicker('datePickerSpecialEndDate');
    }
  };

  handleEarlyStartDate = (dateInput) => {
    const date = new Date(moment(dateInput).startOf('day'));
    const terms = ['Tanggal Mulai Early Bird', 'Tanggal Berakhir Early Bird'];

    const { startDate, earlyEndDate } = this.state;

    if (date > startDate.value) {
      alert(`${terms[0]} harus sebelum Start Date`);
    } else if (earlyEndDate.value && date > earlyEndDate.value) {
      alert(`${terms[0]} harus sebelum ${terms[1]}`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        earlyStartDate: {
          ...prevState.earlyStartDate,
          value: date,
          valid: true,
        },
      }));
      this.handleCloseDatePicker('datePickerEarlyStartDate');
    }
  };

  handleEarlyEndDate = (dateInput) => {
    const date = new Date(moment(dateInput).endOf('day'));
    const terms = [
      'Tanggal Mulai Early Bird',
      'Tanggal Berakhir Early Bird',
      'Tanggal Mulai Promo Term',
    ];

    const { startDate, earlyStartDate, termStartDate } = this.state;

    if (date > startDate.value) {
      alert(`${terms[1]} harus sebelum Start Date`);
    } else if (earlyStartDate.value && date < earlyStartDate.value) {
      alert(`${terms[1]} harus sesudah ${terms[0]}`);
    } else if (termStartDate.value && date > termStartDate.value) {
      alert(`${terms[1]} harus sebelum ${terms[2]}`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        earlyEndDate: { ...prevState.earlyEndDate, value: date, valid: true },
      }));
      this.handleCloseDatePicker('datePickerEarlyEndDate');
    }
  };

  handleTermStartDate = (dateInput) => {
    const date = new Date(moment(dateInput).startOf('day'));
    const terms = [
      'Tanggal Mulai Promo Term',
      'Tanggal Berakhir Promo Term',
      'Tanggal Berakhir Early Bird',
    ];

    const { startDate, earlyEndDate, termEndDate } = this.state;
    if (date > startDate.value) {
      alert(`${terms[0]} harus sebelum Start Date`);
    } else if (termEndDate.value && date > termEndDate.value) {
      alert(`${terms[0]} harus sebelum ${terms[1]}`);
    } else if (earlyEndDate.value && date < earlyEndDate.value) {
      alert(`${terms[0]} harus sesudah ${terms[2]}`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        termStartDate: {
          ...prevState.termStartDate,
          value: date,
          valid: true,
        },
      }));
      this.handleCloseDatePicker('datePickerTermStartDate');
    }
  };

  handleTermEndDate = (dateInput) => {
    const date = new Date(moment(dateInput).endOf('day'));
    const terms = [
      'Tanggal Mulai Promo Term',
      'Tanggal Berakhir Promo Term',
      'Tanggal Berakhir Early Bird',
      'Tanggal Mulai Promo Term 2',
    ];

    const {
      startDate,
      earlyEndDate,
      termStartDate,
      term2StartDate,
    } = this.state;

    if (date > startDate.value) {
      alert(`${terms[1]} harus sebelum Start Date`);
    } else if (termStartDate.value && date < termStartDate.value) {
      alert(`${terms[1]} harus sesudah ${terms[0]}`);
    } else if (earlyEndDate.value && date < earlyEndDate.value) {
      alert(`${terms[1]} harus sesudah ${terms[2]}`);
    } else if (earlyEndDate.value && date < earlyEndDate.value) {
      alert(`${terms[1]} harus sesudah ${terms[2]}`);
    } else if (term2StartDate.value && date > term2StartDate.value) {
      alert(`${terms[1]} harus sebelum ${terms[3]}`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        termEndDate: { ...prevState.termEndDate, value: date, valid: true },
      }));
      this.handleCloseDatePicker('datePickerTermEndDate');
    }
  };

  handleTerm2StartDate = (dateInput) => {
    const date = new Date(moment(dateInput).startOf('day'));
    const terms = [
      'Tanggal Mulai Promo Term 2',
      'Tanggal Berakhir Promo Term 2',
      'Tanggal Berakhir Promo Term',
    ];

    const { startDate, termEndDate, term2EndDate } = this.state;

    if (date > startDate.value) {
      alert(`${terms[0]} harus sebelum Start Date`);
    } else if (term2EndDate.value && date > term2EndDate.value) {
      alert(`${terms[0]} harus sebelum ${terms[1]}`);
    } else if (termEndDate.value && date < termEndDate.value) {
      alert(`${terms[0]} harus sesudah ${terms[2]}`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        term2StartDate: {
          ...prevState.term2StartDate,
          value: date,
          valid: true,
        },
      }));
      this.handleCloseDatePicker('datePickerTerm2StartDate');
    }
  };

  handleTerm2EndDate = (dateInput) => {
    const date = new Date(moment(dateInput).endOf('day'));
    const terms = [
      'Tanggal Mulai Promo Term 2',
      'Tanggal Berakhir Promo Term 2',
      'Tanggal Berakhir Promo Term',
    ];

    const { startDate, earlyEndDate, term2StartDate } = this.state;

    if (date > startDate.value) {
      alert(`${terms[1]} harus sebelum Start Date`);
    } else if (term2StartDate.value && date < term2StartDate.value) {
      alert(`${terms[1]} harus sesudah ${terms[0]}`);
    } else if (earlyEndDate.value && date < earlyEndDate.value) {
      alert(`${terms[1]} harus sesudah ${terms[2]}`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        term2EndDate: { ...prevState.term2EndDate, value: date, valid: true },
      }));
      this.handleCloseDatePicker('datePickerTerm2EndDate');
    }
  };

  handleCorporateSaleStartDate = (dateInput) => {
    const { corporateSaleEndDate, saleEndDate } = this.state;

    let corporateSaleEndDateValue = corporateSaleEndDate.value;

    const givenDate = new Date(moment(dateInput).startOf('day'));
    const startOfSaleEndDate = new Date(
      moment(saleEndDate.value).startOf('day'),
    );

    if (givenDate > startOfSaleEndDate) {
      alert('Tanggal mulai Corporate Sale harus sebelum Sale End Date');
      return;
    }

    if (givenDate > corporateSaleEndDateValue) {
      corporateSaleEndDateValue = givenDate;
    }

    this.setState((prevState) => ({
      ...prevState,
      corporateSaleStartDate: {
        ...prevState.corporateSaleStartDate,
        value: givenDate,
        valid: true,
      },
      corporateSaleEndDate: {
        ...prevState.corporateSaleEndDate,
        value: corporateSaleEndDateValue,
      },
    }));

    this.handleCloseDatePicker('datePickerCorporateSaleStartDate');
  };

  handleCorporateSaleEndDate = (dateInput) => {
    const { corporateSaleStartDate, saleEndDate } = this.state;

    let corporateSaleStartDateValue = corporateSaleStartDate.value;

    const givenDate = new Date(moment(dateInput).endOf('day'));
    const endOfSaleEndDate = new Date(moment(saleEndDate.value).endOf('day'));

    if (givenDate > endOfSaleEndDate) {
      alert('Tanggal berakhir Corporate Sale harus sebelum Sale End Date');
      return;
    }

    if (givenDate < corporateSaleStartDateValue) {
      corporateSaleStartDateValue = givenDate;
    }

    this.setState((prevState) => ({
      ...prevState,
      corporateSaleEndDate: {
        ...prevState.corporateSaleEndDate,
        value: givenDate,
        valid: true,
      },
      corporateSaleStartDate: {
        ...prevState.corporateSaleStartDate,
        value: corporateSaleStartDateValue,
      },
    }));

    this.handleCloseDatePicker('datePickerCorporateSaleEndDate');
  };

  handleSaleEndDate = (dateInput) => {
    const date = new Date(moment(dateInput).endOf('day'));
    this.handleSelectedSaleEndDate(
      'Sale End Date',
      'saleEndDate',
      date,
      'datePickerSaleEndDate',
    );
  };

  handleOpenDatePicker = (selector) => {
    this.setState((prevState) => ({
      ...prevState,
      [selector]: true,
    }));
  };

  handleCloseDatePicker = (selector) => {
    this.setState((prevState) => ({
      ...prevState,
      [selector]: false,
    }));
  };

  handleInput = (state, value) => {
    const parsedValue =
      (state === 'maxSeats' ||
        state === 'specialQuota' ||
        state === 'priceQuota' ||
        state === 'priceQuotaRemaining') &&
      value
        ? parseInt(value, 10)
        : value;
    let valid = false;
    let invalid = true;
    if (
      value ||
      state === 'notes' ||
      state === 'specialInfo' ||
      state === 'practiceDays' ||
      state === 'priceTitle' ||
      state === 'priceTitleDesc' ||
      state === 'priceInfo'
    ) {
      valid = true;
      invalid = false;
    }
    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: parsedValue, valid, invalid },
    }));
  };

  handleInput3 = (state, index, value, prop) => {
    const tempArr = this.state[state].value.slice();
    tempArr[index][prop] = value;
    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: tempArr },
    }));
  };

  handleAddAdditionalPromos = (newPromo, selectedPromoIndex) => {
    const newPromoWithProgramId = {
      ...newPromo,
      programId: this.props.programId,
    };
    const newArrayOfPromo = this.state.programAdditionalPromos.value.concat([
      newPromoWithProgramId,
    ]);
    if (selectedPromoIndex === null) {
      this.setState((prevState) => {
        return {
          ...prevState,
          programAdditionalPromos: {
            ...prevState.programAdditionalPromos,
            value: newArrayOfPromo,
            invalid: false,
          },
        };
      });
    } else {
      const editArrayOfPromo = this.state.programAdditionalPromos.value;
      editArrayOfPromo[selectedPromoIndex] = newPromoWithProgramId;
      this.setState((prevState) => {
        return {
          ...prevState,
          programAdditionalPromos: {
            ...prevState.programAdditionalPromos,
            value: editArrayOfPromo,
            invalid: false,
          },
        };
      });
    }
  };

  handleDeletePromo = (index) => {
    const currentPromos = this.state.programAdditionalPromos.value;
    currentPromos.splice(index, 1);
    const newPromos = currentPromos;
    this.setState((prevState) => {
      return {
        ...prevState,
        programAdditionalPromos: {
          ...prevState.programAdditionalPromos,
          values: newPromos,
          invalid: false,
        },
      };
    });
  };

  handleAddPrices = (newPrice) => {
    const foundSameCurrencyIndex = this.state.programPrices.value.findIndex(
      (price) => {
        return price.currency.id === newPrice.currency.id;
      },
    );
    if (foundSameCurrencyIndex < 0) {
      const newPrices = this.state.programPrices.value.concat([newPrice]);
      this.setState((prevState) => {
        return {
          ...prevState,
          programPrices: {
            ...prevState.programPrices,
            value: newPrices,
            invalid: false,
          },
        };
      });
    } else {
      const currentPrices = this.state.programPrices.value;
      currentPrices[foundSameCurrencyIndex] = newPrice;
      this.setState((prevState) => {
        return {
          ...prevState,
          programPrices: { ...prevState.programPrices, value: currentPrices },
        };
      });
    }
  };

  handleDeletePrices = (index) => {
    const currentPrices = this.state.programPrices.value;
    currentPrices.splice(index, 1);
    const newPrices = currentPrices;

    this.setState((prevState) => {
      return {
        ...prevState,
        programPrices: { ...prevState.programPrices, value: newPrices },
      };
    });
  };

  handleSelect = (state, value) => {
    let valid = false;
    let invalid = true;
    if (value !== 0) {
      valid = true;
      invalid = false;
    }
    this.setState((prevState) => ({
      ...prevState,
      [state]: { value, valid, invalid },
    }));
  };

  // allowNumbersOnly = (e) => {
  //     let code = (e.which) ? e.which : e.keyCode;
  //     if (code > 31 && (code < 48 || code > 57)) {
  //         e.preventDefault();
  //     }
  // }

  resetForm = () => {
    this.setState((prevState) => ({ ...prevState, ...INITIAL_FORM }));
  };

  handleDelete = async (state, index) => {
    const tempArr = this.state[`${state}`].slice();
    tempArr.splice(index, 1);
    if (this.state[`${state}`].length > 1) {
      await this.setState({ [`${state}`]: tempArr });
    } else {
      alert('Minimal harus ada 1');
    }
  };

  onHandleDeleteSpeaker = async (index) => {
    const tempArr = this.state.speakerIds.value;
    tempArr.splice(index, 1);
    this.setState((prevState) => ({
      speakerIds: { ...prevState.speakerIds, value: tempArr },
    }));
  };

  handleDeleteEnd = async (state) => {
    const tempArr = this.state[state].slice();
    tempArr.pop();
    if (this.state[state].length > 1) {
      this.setState({ [state]: tempArr });
    } else {
      alert('Minimal harus ada 1');
    }
  };

  onAddInput = (state) => {
    const temp = this.state[state].value;
    temp.push({ item: '' });
    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: temp },
    }));
  };

  onDeleteInput = (state) => {
    const temp = this.state[state].value;
    temp.pop();
    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: temp },
    }));
  };

  onAddSpeaker = () => {
    if (parseInt(this.state.selectedSpeakerId, 10) === 'none') {
      alert('Please pick a speaker');
    } else {
      const tempArr = this.state.speakerIds.value;
      let check = true;
      tempArr.forEach((item) => {
        if (item.id === this.state.speakers[this.state.selectedSpeakerId].id) {
          check = false;
        }
      });

      if (check) {
        tempArr.push(this.state.speakers[this.state.selectedSpeakerId]);
        this.setState((prevState) => ({
          speakerIds: { ...prevState.speakerIds, value: tempArr },
          selectedSpeakerId: 'none',
        }));
      } else {
        alert('Speaker already added');
      }
    }
  };

  validatePrices = async (pricesValue) => {
    if (pricesValue.length === 0) {
      this.setState((prevState) => ({
        ...prevState,
        programPrices: { ...prevState.programPrices, invalid: true },
      }));
      return false;
    }
    return true;
  };

  validateForm = async (form, programPrices) => {
    let result = 0;

    // validate programPrices if multiple currency is supported
    if (this.props.isMultipleCurrency) {
      const pricesIsValid = await this.validatePrices(programPrices.value);
      if (!pricesIsValid) {
        result += 1;
      }
    }

    await Object.keys(form).forEach(async (obj) => {
      if (!form[obj].valid) {
        await this.setState((prevState) => ({
          [obj]: { ...prevState[obj], invalid: true },
        }));
        result += 1;
      }
    });

    if (result > 0) {
      throw new Error('There are invalid fields');
    }
    return true;
  };

  createSchedule = async () => {
    try {
      const {
        branchId,
        study_methodId,
        study_scheduleId,
        startDate,
        endDate,
        startTime,
        endTime,
        practiceStartTime,
        practiceEndTime,
        days,
        maxSeats,
        price,
        specialPrice,
        earlyPrice,
        termPrice,
        term2Price,
        corporatePrice,
        specialStartDate,
        specialEndDate,
        earlyStartDate,
        earlyEndDate,
        termStartDate,
        termEndDate,
        term2StartDate,
        term2EndDate,
        corporateSaleStartDate,
        corporateSaleEndDate,
        saleEndDate,
        registrationFee,
        speakerIds,
        content,
        syllabus,
        audience,
        notes,
        code,
        programPrices,
        programAdditionalPromos,
        specialDesc,
        specialQuota,
        specialInfo,
        practiceDays,
        priceTitle,
        priceTitleDesc,
        priceQuota,
        priceQuotaRemaining,
        priceInfo,
      } = this.state;
      const optionalParams = this.categoryCheck
        ? {
            content,
            syllabus,
            audience,
            notes,
          }
        : { code };

      await this.validateForm(
        {
          branchId,
          study_methodId,
          study_scheduleId,
          startDate,
          endDate,
          startTime,
          endTime,
          practiceStartTime,
          practiceEndTime,
          days,
          maxSeats,
          // price, earlyPrice,

          saleEndDate,
          speakerIds,
          ...optionalParams,
        },
        programPrices,
      );

      if (window.confirm('Are you sure?')) {
        this.setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        const { search } = this.props.location;
        const params = new URLSearchParams(search);
        const id = params.get('id');

        const optional = this.categoryCheck
          ? {
              content: content.value,
              syllabus: JSON.stringify(syllabus.value),
              audience: JSON.stringify(audience.value),
              notes: notes.value,
            }
          : { code: code.value };

        const data = {
          branchId: branchId.value,
          study_methodId: study_methodId.value,
          study_scheduleId: study_scheduleId.value,
          startDate: startDate.value,
          endDate: endDate.value,
          startTime: startTime.value,
          endTime: endTime.value,
          practiceStartTime: practiceStartTime.value,
          practiceEndTime: practiceEndTime.value,
          days: days.value,
          maxSeats: maxSeats.value,
          normalPrice: price.value,
          corporatePrice: corporatePrice.value,
          registrationFee: registrationFee.value,
          specialPrice: specialPrice.value,
          specialStartDate: specialStartDate.value,
          specialEndDate: specialEndDate.value,
          earlyPrice: earlyPrice.value,
          earlyStartDate: earlyStartDate.value,
          earlyEndDate: earlyEndDate.value,
          termPrice: termPrice.value,
          termStartDate: termStartDate.value,
          termEndDate: termEndDate.value,
          term2Price: term2Price.value,
          term2StartDate: term2StartDate.value,
          term2EndDate: term2EndDate.value,

          // NOTE: tenary to null so it does not results to an empty string / 0
          priceTitle: nullParser(priceTitle.value),
          priceTitleDesc: nullParser(priceTitleDesc.value),
          priceInfo: nullParser(priceInfo.value),
          priceQuota: nullParser(priceQuota.value),
          priceQuotaRemaining: nullParser(priceQuotaRemaining.value),
          specialQuota: nullParser(specialQuota.value),
          specialDesc: nullParser(specialDesc.value),
          specialInfo: nullParser(specialInfo.value),
          practiceDays: nullParser(practiceDays.value),

          corporateSaleStartDate: corporateSaleStartDate.value,
          corporateSaleEndDate: corporateSaleEndDate.value,
          saleEndDate: saleEndDate.value,
          speakerIds: speakerIds.value,
          programPrices: programPrices.value,
          programAdditionalPromos: programAdditionalPromos.value,
          hasWorkshopDetails: this.categoryCheck,
          ...optional,
        };

        const programHeaderId = id;

        if (this.categoryCheck) {
          // for program category Workshop (4) & Event (5)
          await axios.post(`/admin/create/schedule/${programHeaderId}`, data);
        } else {
          await axios.post(`/v2/program/${programHeaderId}`, data);
        }

        this.resetForm();
        this.props.toggle();
        this.props.refresh();
      }
    } catch (err) {
      this.setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      alert(err);
      console.log(err);
    }
  };

  editSchedule = async () => {
    try {
      const {
        branchId,
        study_methodId,
        study_scheduleId,
        startDate,
        endDate,
        startTime,
        endTime,
        practiceStartTime,
        practiceEndTime,
        days,
        maxSeats,
        price,
        specialPrice,
        earlyPrice,
        termPrice,
        term2Price,
        corporatePrice,
        specialStartDate,
        specialEndDate,
        earlyStartDate,
        earlyEndDate,
        termStartDate,
        termEndDate,
        term2StartDate,
        term2EndDate,
        specialDesc,
        specialQuota,
        specialInfo,
        practiceDays,
        priceTitle,
        priceTitleDesc,
        priceQuota,
        priceQuotaRemaining,
        priceInfo,

        corporateSaleStartDate,
        corporateSaleEndDate,
        saleEndDate,
        registrationFee,
        speakerIds,
        content,
        syllabus,
        audience,
        notes,
        code,
        programPrices,
        programAdditionalPromos,
      } = this.state;
      const optionalParams = this.categoryCheck
        ? { content, syllabus, audience, notes }
        : { code };

      await this.validateForm(
        {
          branchId,
          study_methodId,
          study_scheduleId,
          startDate,
          endDate,
          startTime,
          endTime,
          practiceStartTime,
          practiceEndTime,
          days,
          maxSeats,
          // price, earlyPrice,
          saleEndDate,
          speakerIds,
          ...optionalParams,
        },
        programPrices,
      );

      if (window.confirm('Are you sure?')) {
        this.setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));

        const optional = this.categoryCheck
          ? {
              content: content.value,
              syllabus: JSON.stringify(syllabus.value),
              audience: JSON.stringify(audience.value),
              notes: notes.value,
            }
          : { code: code.value };

        const data = {
          branchId: branchId.value,
          study_methodId: study_methodId.value,
          study_scheduleId: study_scheduleId.value,
          startDate: startDate.value,
          endDate: endDate.value,
          startTime: startTime.value,
          endTime: endTime.value,
          practiceStartTime: practiceStartTime.value,
          practiceEndTime: practiceEndTime.value,
          days: days.value,
          maxSeats: maxSeats.value,
          normalPrice: price.value,
          corporatePrice: corporatePrice.value,
          registrationFee: registrationFee.value,
          specialPrice: specialPrice.value,
          specialStartDate: specialStartDate.value,
          specialEndDate: specialEndDate.value,
          earlyPrice: earlyPrice.value,
          earlyStartDate: earlyStartDate.value,
          earlyEndDate: earlyEndDate.value,
          termPrice: termPrice.value,
          termStartDate: termStartDate.value,
          termEndDate: termEndDate.value,
          term2Price: term2Price.value,
          term2StartDate: term2StartDate.value,
          term2EndDate: term2EndDate.value,

          // NOTE: tenary to null so it does not results to an empty string / 0
          priceTitle: nullParser(priceTitle.value),
          priceTitleDesc: nullParser(priceTitleDesc.value),
          priceInfo: nullParser(priceInfo.value),
          priceQuota: nullParser(priceQuota.value),
          priceQuotaRemaining: nullParser(priceQuotaRemaining.value),
          specialQuota: nullParser(specialQuota.value),
          specialDesc: nullParser(specialDesc.value),
          specialInfo: nullParser(specialInfo.value),
          practiceDays: nullParser(practiceDays.value),

          corporateSaleStartDate: corporateSaleStartDate.value,
          corporateSaleEndDate: corporateSaleEndDate.value,
          saleEndDate: saleEndDate.value,
          speakerIds: speakerIds.value,
          hasWorkshopDetails: this.categoryCheck,
          programPrices: programPrices.value,
          programAdditionalPromos: programAdditionalPromos.value,
          ...optional,
        };

        await setTimeout(() => {
          axios
            .put(
              this.categoryCheck
                ? // for program category Workshop (4) & Event (5)
                  `/admin/program/schedule/${this.props.programId}`
                : `/v2/program/${this.props.programId}`,
              data,
            )
            .then(() => {
              alert('Update Schedule Success');
              this.setState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));
              this.resetForm();
              this.props.toggle();
              this.props.refresh();
            })
            .catch((err) => {
              this.setState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));
              alert(err);
              console.log(err);
            });
        }, 400); // Loads for a minimal of 0.4s
      }
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };

  BranchSelect = ({ value, valid, invalid, error, label }) => {
    const arrJSX = this.state.branches.map((item) => {
      return (
        <option key={`branch${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    return (
      <FormGroup className="mb-2">
        <Label for={`FormGroup${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) => this.handleSelect('branchId', target.value)}
        >
          <option value={0} disabled hidden>
            Pilih Branch
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  StudyMethodSelect = ({ value, valid, invalid, error, label }) => {
    const arrJSX = this.state.studyMethods.map((item) => {
      return (
        <option key={`studymethod${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    const livestreamId = this.state.studyMethods
      ?.filter((el) => el.name === 'Livestream Class')
      ?.map((el) => el.id);

    const videoLearningId = this.state.studyMethods
      ?.filter((el) => el.name === 'Video Learning')
      ?.map((el) => el.id);

    const livestreamBranch = this.state.branches
      ?.filter((el) => el.name === 'Purwadhika Livestream Class')
      .map((el) => el.id);

    const videoLearningSchedule = this.state.studySchedules
      ?.filter((el) => el.name === 'Purwadhika Video Learning')
      .map((el) => el.id);

    return (
      <FormGroup className="mb-2">
        <Label for={`FormGroup${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) => {
            this.handleSelect('study_methodId', target.value);
            if (livestreamId?.includes(parseInt(target.value, 10))) {
              this.handleSelect('branchId', livestreamBranch[0]);
              this.handleSelect('study_scheduleId', 0);
            } else if (videoLearningId?.includes(parseInt(target.value, 10))) {
              this.handleSelect('branchId', 0);
              this.handleSelect('study_scheduleId', videoLearningSchedule[0]);
            } else {
              this.handleSelect('branchId', 0);
              this.handleSelect('study_scheduleId', 0);
            }
          }}
        >
          <option value={0} disabled hidden>
            Pilih Metode Belajar
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  StudyScheduleSelect = ({ value, valid, invalid, error, label }) => {
    const arrJSX = this.state.studySchedules.map((item) => {
      return (
        <option key={`studyschedule${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    return (
      <FormGroup className="mb-2">
        <Label for={`FormGroup${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) =>
            this.handleSelect('study_scheduleId', target.value)
          }
        >
          <option value={0} disabled hidden>
            Pilih Jadwal Belajar
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  SpeakerSelect = () => {
    const arrJSX = this.state.speakers.map((item, index) => {
      return (
        <option key={`speaker${item.id}`} value={index}>
          {item.name}
        </option>
      );
    });

    return (
      <FormGroup className="mb-2">
        <Label for="form-select-speaker" className="text-gray font-weight-bold">
          Select Speaker
        </Label>
        <InputGroup>
          <Input
            type="select"
            value={this.state.selectedSpeakerId}
            id="speakerselect"
            onChange={({ target }) =>
              this.setState({ selectedSpeakerId: target.value })
            }
          >
            <option value="none" disabled hidden>
              Pilih Speaker
            </option>
            {arrJSX}
          </Input>
          <InputGroupAddon addonType="append">
            <Button
              onClick={this.onAddSpeaker}
              disabled={this.state.selectedSpeakerId === 'none'}
              color="success"
            >
              Add
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
    );
  };

  handleCurrencyInput = (state, value) => {
    let number = parseInt(value.split(',').join(''), 10);
    let displayValue = number.toLocaleString();

    if (value.split(',').join('') === '') {
      number = 0;
      displayValue = '0';
    }
    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: number, displayValue },
    }));
  };

  PriceInput = ({ displayValue, valid, invalid, error, label, isFree }) => {
    return (
      <FormGroup>
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            valid={valid}
            invalid={invalid}
            disabled={isFree}
            value={displayValue}
            onChange={({ target }) =>
              this.handleCurrencyInput('price', target.value)
            }
          />
        </InputGroup>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  SpecialPriceInput = ({ displayValue, valid, invalid, label, isFree }) => {
    return (
      <FormGroup>
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          Special Price
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            valid={valid}
            invalid={invalid}
            disabled={isFree}
            value={displayValue}
            onChange={({ target }) =>
              this.handleCurrencyInput('specialPrice', target.value)
            }
          />
        </InputGroup>
      </FormGroup>
    );
  };

  EarlyPriceInput = ({ displayValue, valid, invalid, label, isFree }) => {
    return (
      <FormGroup>
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          Early Bird Price
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            valid={valid}
            invalid={invalid}
            disabled={isFree}
            value={displayValue}
            onChange={({ target }) =>
              this.handleCurrencyInput('earlyPrice', target.value)
            }
          />
        </InputGroup>
      </FormGroup>
    );
  };

  TermPriceInput = ({ displayValue, valid, invalid, label, isFree }) => {
    return (
      <FormGroup>
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          Term Price
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            valid={valid}
            invalid={invalid}
            disabled={isFree}
            value={displayValue}
            onChange={({ target }) =>
              this.handleCurrencyInput('termPrice', target.value)
            }
          />
        </InputGroup>
      </FormGroup>
    );
  };

  Term2PriceInput = ({ displayValue, valid, invalid, label, isFree }) => {
    return (
      <FormGroup>
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          Term 2 Price
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            valid={valid}
            invalid={invalid}
            disabled={isFree}
            value={displayValue}
            onChange={({ target }) =>
              this.handleCurrencyInput('term2Price', target.value)
            }
          />
        </InputGroup>
      </FormGroup>
    );
  };

  CorporatePriceInput = ({ displayValue, invalid, valid, label, isFree }) => {
    return (
      <FormGroup>
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          Corporate Price
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            valid={valid}
            invalid={invalid}
            disabled={isFree}
            value={displayValue}
            onChange={({ target }) =>
              this.handleCurrencyInput('corporatePrice', target.value)
            }
          />
        </InputGroup>
      </FormGroup>
    );
  };

  RegistrationFeeInput = ({ displayValue, valid, invalid, label }) => {
    return (
      <FormGroup>
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          Registration Fee
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            valid={valid}
            invalid={invalid}
            disabled
            value={displayValue}
            onChange={() => this.handleCurrencyInput('registrationFee', '0')}
          />
        </InputGroup>
      </FormGroup>
    );
  };

  SyllabusInput = ({ value = [] }) => {
    const arrJSX = value.map((item, index) => {
      return (
        <div>
          <div>
            <Badge>0{index + 1}</Badge>
          </div>
          <div>
            <HTMLEditor2
              id={`inputsyllabusdescription${index}`}
              handleInput={(state, idx, val, prop) =>
                this.handleInput3(state, idx, val, prop)
              }
              value={item.item}
              name="syllabus"
              index={index}
              prop="item"
            />
          </div>
        </div>
      );
    });

    if (this.categoryCheck) {
      return (
        <Card className="my-4">
          <CardHeader>
            <div className="d-flex flex-row justify-content-between">
              <div>Apa saja yang akan dipelajari?</div>
              <div>
                <Button
                  color="success"
                  size="sm"
                  style={{ width: '30px' }}
                  onClick={() => this.onAddInput('syllabus')}
                >
                  +
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  style={{ width: '30px' }}
                  onClick={() => this.onDeleteInput('syllabus')}
                >
                  -
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>{arrJSX}</CardBody>
        </Card>
      );
    }
    return null;
  };

  AudienceInput = ({ value = [] }) => {
    const arrJSX = value.map((item, index) => {
      return (
        <div>
          <div>
            <Badge>0{index + 1}</Badge>
          </div>
          <div>
            <HTMLEditor2
              id={`inputaudiencedescription${index}`}
              handleInput={(state, idx, val, prop) =>
                this.handleInput3(state, idx, val, prop)
              }
              value={item.item}
              name="audience"
              index={index}
              prop="item"
            />
          </div>
        </div>
      );
    });

    if (this.categoryCheck) {
      return (
        <Card className="my-4">
          <CardHeader>
            <div className="d-flex flex-row justify-content-between">
              <div>Untuk siapa acara ini ditujukan?</div>
              <div>
                <Button
                  color="success"
                  size="sm"
                  style={{ width: '30px' }}
                  onClick={() => this.onAddInput('audience')}
                >
                  +
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  style={{ width: '30px' }}
                  onClick={() => this.onDeleteInput('audience')}
                >
                  -
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>{arrJSX}</CardBody>
        </Card>
      );
    }
    return null;
  };

  onActionSpeaker = (edit = false, speakerData = null) => {
    this.setState((prevState) => ({
      ...prevState,
      speakerForm: !prevState.speakerForm,
      editMode: edit,
      speakerData,
    }));
  };

  SelectSpeakerCard = () => {
    if (this.categoryCheck) {
      return (
        <Card className="my-4">
          <CardHeader>
            Speaker
            <Button
              size="sm"
              className="float-right"
              onClick={() => this.onActionSpeaker(false)}
            >
              +Create
            </Button>
          </CardHeader>
          <CardBody>
            {this.SpeakerSelect()}

            <SelectedSpeakers
              speakers={this.state.speakerIds.value}
              onDelete={this.onHandleDeleteSpeaker}
              onActionSpeaker={this.onActionSpeaker}
              listSpeakers={this.state.speakers}
            />
          </CardBody>
          <SpeakerForm
            isOpen={this.state.speakerForm}
            toggle={this.onActionSpeaker}
            refresh={this.getSpeakers}
            isEdit={this.state.editMode}
            speakerData={this.state.speakerData}
          />
        </Card>
      );
    }
    return null;
  };

  WorkshopDetailCard = () => {
    if (this.categoryCheck) {
      return (
        <Card className="my-4">
          <CardHeader>Workshop/Event Details</CardHeader>
          <CardBody>
            <HTMLForm
              {...this.state.content}
              label="Mengapa harus mengikuti acara ini"
              onInput={(value) => this.handleInput('content', value)}
            />
            <HTMLForm
              {...this.state.notes}
              label="Notes"
              onInput={(value) => this.handleInput('notes', value)}
            />
          </CardBody>
        </Card>
      );
    }
    return null;
  };

  CreateButton = ({ onClick, isLoading }) => {
    if (isLoading)
      return (
        <Button color="secondary" className="rounded-right w-100 my-2" disabled>
          <Loader type="Oval" color="white" height={18} width="100%" />
        </Button>
      );
    return (
      <Button
        color="success"
        className="rounded-right w-100 my-2"
        onClick={onClick}
        style={{ backgroundColor: '#53b59f' }}
      >
        Create
      </Button>
    );
  };

  EditButton = ({ onClick, isLoading }) => {
    if (isLoading)
      return (
        <Button color="secondary" className="rounded-right w-100 my-2" disabled>
          <Loader type="Oval" color="white" height={18} width="100%" />
        </Button>
      );
    return (
      <Button
        color="success"
        className="rounded-right w-100 my-2"
        onClick={onClick}
        style={{ backgroundColor: '#53b59f' }}
      >
        Edit
      </Button>
    );
  };

  CancelButton = ({ onClick }) => {
    return (
      <Button
        color="danger"
        className="rounded-right w-100 my-2"
        onClick={onClick}
      >
        Cancel
      </Button>
    );
  };

  handleStartTime = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      startTime: { ...prevState.startTime, value },
    }));
  };

  handleEndTime = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      endTime: { ...prevState.endTime, value },
    }));
  };

  handlePracticeStartTime = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      practiceStartTime: { ...prevState.practiceStartTime, value },
    }));
  };

  handlePracticeEndTime = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      practiceEndTime: { ...prevState.practiceEndTime, value },
    }));
  };

  render() {
    // if(this.props.render){
    return (
      <div className="white-box py-2 container">
        <div className="row">
          <div className="col-12">
            <Card className="my-4">
              <CardHeader>Tempat dan Waktu</CardHeader>
              <CardBody>
                <this.StudyMethodSelect
                  {...this.state.study_methodId}
                  label="Metode Belajar"
                />
                <this.StudyScheduleSelect
                  {...this.state.study_scheduleId}
                  label="Jadwal Belajar"
                />
                <this.BranchSelect {...this.state.branchId} label="Branch" />
                <StartEndDatePicker
                  handleStartEndDate={this.handleStartEndDate}
                  startDate={this.state.startDate.value}
                  endDate={this.state.endDate.value}
                />
                <InputForm
                  {...this.state.days}
                  label="Training Session Days"
                  onInput={(value) => this.handleInput('days', value)}
                />
                <FormGroup className="d-flex justify-content-around">
                  <div className="w-100">
                    <Label
                      for="formStartDate"
                      className="text-gray font-weight-bold"
                    >
                      Training Session Start Time
                    </Label>
                    <div>
                      <TimePicker
                        onChange={this.handleStartTime}
                        value={this.state.startTime.value}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <Label
                      for="formEndDate"
                      className="text-gray font-weight-bold"
                    >
                      Training Session End Time
                    </Label>
                    <div>
                      <TimePicker
                        onChange={this.handleEndTime}
                        value={this.state.endTime.value}
                      />
                    </div>
                  </div>
                </FormGroup>
                <InputForm
                  {...this.state.practiceDays}
                  label="Practice Session Days"
                  onInput={(value) => this.handleInput('practiceDays', value)}
                />
                <FormGroup className="d-flex justify-content-around">
                  <div className="w-100">
                    <Label
                      for="formStartDate"
                      className="text-gray font-weight-bold"
                    >
                      Practice Session Start Time
                    </Label>
                    <div>
                      <TimePicker
                        onChange={this.handlePracticeStartTime}
                        value={this.state.practiceStartTime.value}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <Label
                      for="formEndDate"
                      className="text-gray font-weight-bold"
                    >
                      Practice Session End Time
                    </Label>
                    <div>
                      <TimePicker
                        onChange={this.handlePracticeEndTime}
                        value={this.state.practiceEndTime.value}
                      />
                    </div>
                  </div>
                </FormGroup>
                {!this.categoryCheck ? (
                  <InputForm
                    {...this.state.code}
                    label="Code"
                    onInput={(value) => this.handleInput('code', value)}
                  />
                ) : null}

                <InputForm
                  {...this.state.maxSeats}
                  label="Max Seats"
                  onInput={(value) => this.handleInput('maxSeats', value)}
                  type="number"
                />
              </CardBody>
            </Card>
            <Card className="my-4">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>Price Details</div>
                  <div>
                    {this.props.isMultipleCurrency && (
                      <Button
                        size="sm"
                        onClick={this.togglePriceInputModal}
                        className="mr-1"
                      >
                        Add Price
                      </Button>
                    )}

                    {/* NOTE: temporary hidden */}
                    {/* <Button size="sm" onClick={this.togglePromoInputModal}>
                      Add Promo
                    </Button> */}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <InputForm
                  {...this.state.priceTitle}
                  label="Price Title"
                  onInput={(value) => this.handleInput('priceTitle', value)}
                />
                <InputForm
                  {...this.state.priceTitleDesc}
                  label="Price Title Description"
                  onInput={(value) => this.handleInput('priceTitleDesc', value)}
                />
                <InputForm
                  {...this.state.priceQuota}
                  label="Price Quota"
                  onInput={(value) => this.handleInput('priceQuota', value)}
                  type="number"
                />
                <InputForm
                  {...this.state.priceQuotaRemaining}
                  label="Price Quota Remaining"
                  onInput={(value) =>
                    this.handleInput('priceQuotaRemaining', value)
                  }
                  type="number"
                />
                <HTMLForm
                  {...this.state.priceInfo}
                  label="Price Information"
                  onInput={(value) => this.handleInput('priceInfo', value)}
                />
                <br />
                {this.props.isMultipleCurrency ? (
                  <>
                    <Label className="text-gray font-weight-bold">
                      Currency
                    </Label>
                    <Table bordered>
                      <thead>
                        <tr>
                          <td>Currency</td>
                          <td>Normal Price</td>
                          <td>Early Bird Price</td>
                          <td>Registration Fee</td>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.programPrices.value.map((price, index) => {
                          const {
                            currency,
                            normalPrice,
                            earlyPrice,
                            registrationFee,
                          } = price;

                          const { id: currencyId, name, symbol } =
                            currency || {};
                          return (
                            <tr key={currencyId}>
                              <th scope="row">{name}</th>
                              <td>
                                <NumberFormat
                                  value={normalPrice}
                                  displayType="text"
                                  thousandSeparator
                                  prefix={symbol}
                                />
                              </td>
                              <td>
                                <NumberFormat
                                  value={earlyPrice}
                                  displayType="text"
                                  thousandSeparator
                                  prefix={symbol}
                                />
                              </td>
                              <td>
                                <NumberFormat
                                  value={registrationFee}
                                  displayType="text"
                                  thousandSeparator
                                  prefix={symbol}
                                />
                              </td>
                              <td>
                                <div>
                                  <Button
                                    className="mr-2"
                                    onClick={() => {
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        selectedPrice: price,
                                      }));
                                      this.togglePriceInputModal();
                                    }}
                                    size="sm"
                                    type="button"
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      this.handleDeletePrices(index);
                                    }}
                                    size="sm"
                                    type="button"
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {this.state.programPrices.invalid && (
                      <Alert color="danger">
                        Please input a minimum of one price
                      </Alert>
                    )}
                  </>
                ) : (
                  <>
                    <this.PriceInput
                      label="Normal Price"
                      {...this.state.price}
                    />
                    <this.Term2PriceInput
                      label="Term 2 Price"
                      {...this.state.term2Price}
                    />
                    <this.TermPriceInput
                      label="Term Price"
                      {...this.state.termPrice}
                    />
                    <this.EarlyPriceInput
                      label="Early Bird"
                      {...this.state.earlyPrice}
                    />
                    <this.SpecialPriceInput
                      label="Special Price"
                      {...this.state.specialPrice}
                    />
                    <this.CorporatePriceInput
                      label="Corporate"
                      {...this.state.corporatePrice}
                    />
                    <this.RegistrationFeeInput
                      label="Registration Fee"
                      {...this.state.registrationFee}
                    />
                    <br />
                  </>
                )}
                {/* NOTE: Temporary Hidden */}
                {/* <Label className="text-gray font-weight-bold">Promos</Label>
                <Table bordered>
                  <thead>
                    <tr>
                      <td>Promo Message</td>
                      <td>Start Date</td>
                      <td>End Date</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.programAdditionalPromos.value.length
                      ? this.state.programAdditionalPromos.value.map(
                          (promo, index) => {
                            return (
                              <tr>
                                <td>{promo.promoMessage}</td>
                                <td>
                                  {moment(promo.startDate).format(
                                    'DD/MMM/YYYY',
                                  )}
                                </td>
                                <td>
                                  {moment(promo.endDate).format('DD/MMM/YYYY')}
                                </td>
                                <td>
                                  <div>
                                    <Button
                                      className="mr-2"
                                      onClick={() => {
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          selectedPromo: { ...promo, index },
                                        }));
                                        this.togglePromoInputModal();
                                      }}
                                      size="sm"
                                      type="button"
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        this.handleDeletePromo(index);
                                      }}
                                      size="sm"
                                      j
                                      type="button"
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          },
                        )
                      : null}
                  </tbody>
                </Table>
                <br />

                <InputForm
                  {...this.state.specialDesc}
                  label="Special Price Description"
                  onInput={(value) => this.handleInput('specialDesc', value)}
                />
                <InputForm
                  {...this.state.specialQuota}
                  label="Special Price Quota"
                  onInput={(value) => this.handleInput('specialQuota', value)}
                  type="number"
                />
                <HTMLForm
                  {...this.state.specialInfo}
                  label="Special Price Information"
                  onInput={(value) => this.handleInput('specialInfo', value)}
                />
                <br /> */}
                <EarlyStartEndDatePicker
                  handleSpecialStartDate={this.handleSpecialStartDate}
                  handleSpecialEndDate={this.handleSpecialEndDate}
                  datePickerSpecialStartDate={
                    this.state.datePickerSpecialStartDate
                  }
                  datePickerSpecialEndDate={this.state.datePickerSpecialEndDate}
                  specialStartDate={this.state.specialStartDate}
                  specialEndDate={this.state.specialEndDate}
                  handleEarlyStartDate={this.handleEarlyStartDate}
                  handleEarlyEndDate={this.handleEarlyEndDate}
                  datePickerEarlyStartDate={this.state.datePickerEarlyStartDate}
                  datePickerEarlyEndDate={this.state.datePickerEarlyEndDate}
                  earlyStartDate={this.state.earlyStartDate}
                  earlyEndDate={this.state.earlyEndDate}
                  handleTermStartDate={this.handleTermStartDate}
                  handleTermEndDate={this.handleTermEndDate}
                  handleTerm2StartDate={this.handleTerm2StartDate}
                  handleTerm2EndDate={this.handleTerm2EndDate}
                  datePickerTermStartDate={this.state.datePickerTermStartDate}
                  datePickerTermEndDate={this.state.datePickerTermEndDate}
                  datePickerTerm2StartDate={this.state.datePickerTerm2StartDate}
                  datePickerTerm2EndDate={this.state.datePickerTerm2EndDate}
                  termStartDate={this.state.termStartDate}
                  termEndDate={this.state.termEndDate}
                  term2StartDate={this.state.term2StartDate}
                  term2EndDate={this.state.term2EndDate}
                  handleCorporateSaleStartDate={
                    this.handleCorporateSaleStartDate
                  }
                  corporateSaleStartDate={this.state.corporateSaleStartDate}
                  datePickerCorporateSaleStartDate={
                    this.state.datePickerCorporateSaleStartDate
                  }
                  handleCorporateSaleEndDate={this.handleCorporateSaleEndDate}
                  corporateSaleEndDate={this.state.corporateSaleEndDate}
                  datePickerCorporateSaleEndDate={
                    this.state.datePickerCorporateSaleEndDate
                  }
                  handleSaleEndDate={this.handleSaleEndDate}
                  datePickerSaleEndDate={this.state.datePickerSaleEndDate}
                  saleEndDate={this.state.saleEndDate}
                  openDatePicker={this.handleOpenDatePicker}
                  closeDatePicker={this.handleCloseDatePicker}
                />
              </CardBody>
            </Card>
            {this.SelectSpeakerCard()}
          </div>
          <div className="col-12">
            <this.WorkshopDetailCard />
            <this.SyllabusInput {...this.state.syllabus} />
            <this.AudienceInput {...this.state.audience} />
            {this.props.edit ? (
              <this.EditButton
                onClick={this.editSchedule}
                isLoading={this.state.isLoading}
              />
            ) : (
              <this.CreateButton
                onClick={this.createSchedule}
                isLoading={this.state.isLoading}
              />
            )}
            <this.CancelButton
              onClick={() => {
                this.resetForm();
                this.props.toggle();
              }}
            />
          </div>
        </div>
        <PriceInputModal
          isOpen={this.state.priceInputModalOpen}
          toggle={this.togglePriceInputModal}
          handleAddPrices={this.handleAddPrices}
          selectedPrice={this.state.selectedPrice}
          resetSelectedPrice={() => {
            this.setState((prevState) => ({ ...prevState, selectedPrice: {} }));
          }}
        />
        <PromoInputModal
          handleAddAdditionalPromos={this.handleAddAdditionalPromos}
          isOpen={this.state.promoInputModalOpen}
          toggle={this.togglePromoInputModal}
          selectedPromo={this.state.selectedPromo}
          resetSelectedPromo={() => {
            this.setState((prevState) => ({ ...prevState, selectedPromo: {} }));
          }}
        />
      </div>
    );
    // }
    // else{
    //     return <Redirect to={"/"}/>
    // }
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const render = checkForm(auth.access, 'Event Create');
  return { auth, render };
};

export default withRouter(connect(mapStateToProps, {})(ScheduleForm));
